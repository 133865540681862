import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'

import kultMinorLogo from '../../images/kult-minor-logo.png';
import kesajLogo from '../../images/kesaj-logo.jpg';


const Footer = () => {
    return <Box component="footer" sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
            theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
    }}>
        <Container maxWidth="md" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
                <div>
                    <Link href="/o-nas" color="inherit" >O nás</Link>
                </div>
                <br />
                <div>
                    <Link href="/orientacia-v-slovniku" color="inherit" >Orientácia v slovníku</Link>
                </div>
                <br />
                <div>
                    <Link href="/zdroje" color="inherit" >Zdroje</Link>
                </div>
            </div>
            <div style={{ textAlign: 'right', display: 'flex' }}>
                <div style={{ marginRight: '10px' }}>
                    <img src={kultMinorLogo} alt="logo Fondu na podporu kultúry národnostných menšín" style={{ width: "150px" }} />
                </div>
                <div>
                    <img src={kesajLogo} alt="logo rómskej nadácie Kesaj" style={{ height: "51px" }} />
                </div>
            </div>
        </Container>
    </Box>
}

export default Footer