import React from 'react'
import { createContext, useState } from "react";
import { DatabasePath, SK } from '../components/constants';

export type Lang = keyof typeof DatabasePath

type LanguageContextType = {
    selectedLanguage: Lang;
    selectLanguage: (language: Lang) => void;
};

const LanguageContext = createContext<LanguageContextType>({
    selectedLanguage: SK,
    selectLanguage: (language: Lang) => { }
});

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedLanguage, setSelectedLanguage] = useState<Lang>(SK)

    const selectLanguage = (language: Lang): void => {
        setSelectedLanguage(language);
    };

    return (
        <LanguageContext.Provider value={{ selectedLanguage, selectLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageContext; 