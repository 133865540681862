import {
	TextField,
	Button,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import React, { useState, useCallback, useContext } from 'react';
import { db } from '../../utils/firebase';
import {
	DocumentData,
	collection,
	getDocs,
	query,
	where,
} from 'firebase/firestore/lite';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import './Search.css';
import { SK, DatabasePath } from '../constants';
import { useEffect } from 'react';
import { Lang } from '../../context/LanguageContext';
import LanguageContext from '../../context/LanguageContext';

const Search = ({
	handleSetSigns,
}: {
	handleSetSigns: (signs: { id: string; data: DocumentData }[]) => void;
}) => {
	const [searchQuery, setSearchQuery] = useState('');
	const { selectedLanguage, selectLanguage } = useContext(LanguageContext);
	const [dbPath, setDbPath] = useState(DatabasePath[SK]);

	useEffect(() => {
		setDbPath(DatabasePath[selectedLanguage]);
		setSearchQuery('');
		handleSetSigns([])
	}, [handleSetSigns, selectedLanguage]);

	const handleSearchChange = useCallback(
		(e) => setSearchQuery(e.target.value),
		[]
	);

	const searchTerm = searchQuery.trim().toLowerCase();
	const strLength = searchTerm.length;
	const strFrontCode = searchTerm.slice(0, strLength - 1);
	const strEndCode = searchTerm.slice(strLength - 1, strLength);
	const endCode =
		strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

	const dictionaryApi = useCallback(async () => {
		if (strFrontCode.length >= 0) {
			const wordsQuery = query(
				collection(db, dbPath),
				where('sign_insensitive', '>=', searchTerm),
				where('sign_insensitive', '<', endCode)
			);
			const wordsSnapshot = await getDocs(wordsQuery);
			const wordsList = await Promise.all(
				wordsSnapshot.docs.map(async (doc) => {
					const lemma = {
						id: doc.id,
						data: doc.data(),
					};

					return lemma;
				})
			);

			handleSetSigns(wordsList.sort((a, b) => a.data.order - b.data.order));
		}
	}, [dbPath, endCode, handleSetSigns, searchTerm, strFrontCode.length]);

	const handleSubmitClick = useCallback(() => {
		dictionaryApi();
	}, [dictionaryApi]);

	const handleFieldKeyPress = useCallback(
		(ev) => {
			if (ev.key === 'Enter') {
				handleSubmitClick();
			}
		},
		[handleSubmitClick]
	);

	const handleLangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		selectLanguage((event.target as HTMLInputElement).value as Lang);
	};

	return (
		<>
			<FormControl style={{ marginBottom: '10px' }}>
				<RadioGroup
					row
					aria-labelledby="lang-radio-buttons-group-label"
					defaultValue="SK"
					name="lang-radio-buttons-group"
					onChange={handleLangChange}
				>
					<FormControlLabel
						value="SK"
						control={<Radio size="small" />}
						label="Slovensko-rómsky"
					/>
					<FormControlLabel
						value="ROM"
						control={<Radio size="small" />}
						label="Rómsko-slovenský"
					/>
				</RadioGroup>
			</FormControl>
			<TextField
				className="search"
				id="search-input"
				label="Vyhľadajte výraz"
				variant="standard"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchOutlined />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							<Button
								variant="text"
								className="search-btn"
								onClick={handleSubmitClick}
								type="submit"
							>
								Vyhľadať
							</Button>
						</InputAdornment>
					),
				}}
				value={searchQuery}
				onChange={handleSearchChange}
				onKeyDown={handleFieldKeyPress}
			/>
		</>
	);
};

export default Search;
